<template>
  <div ref="searchbox" class="dropdown w-full lg:w-auto">
    <div class="join w-full pt-1.5 pb-0">
      <label
          class="input w-full lg:w-[40rem] input-sm lg:input-md input-bordered join-item flex items-center justify-between gap-4">
        <input
            v-model="data.searchword"
            class="w-[calc(100%-2rem)]"
            name="searchword"
            type="text"
            :placeholder="placeholder"
            @click="showResults"
            @keyup="doSearch"
        />
        <span v-if="data.searching" class="loading loading-spinner loading-sm"></span>
        <XMarkIcon
            v-else-if="data.searchword"
            class="cursor-pointer w-6 h-6 text-primary-500"
            @click="clearSearch"
        />
      </label>
      <button class="btn btn-primary btn-sm lg:btn-md join-item text-white" @click="goToSearch">
        <MagnifyingGlassIcon class="inline-block w-6 h-6"/>
        <span class="hidden lg:inline-block">{{ __("Otsi") }}</span>
      </button>
    </div>
    <div
        class="w-full shadow border border-gray-300 menu z-[60] bg-base-100 right-0 rounded"
        :class="{ 'visible dropdown-open': data.visible, 'hidden': !data.visible }"
    >
      <div
          v-if="data.results.length === 0"
          @click="hideSearch"
      >
        <div class="text-xl">{{ __("Ei leitud ühtegi tulemust.") }}</div>
        <div class="text-sm pt-2">
          {{ __("Võid proovida mõne muu otsisõnaga.") }}
        </div>
      </div>

      <div class="join join-vertical rounded-none">
        <Link v-if="isMiele" class="join-item btn btn-ghost" :href="trRoute('miele')" @click="hideSearch">
          {{ __("Miele pood") }}
        </Link>
        <Link
            v-for="product in data.results"
            :key="product.code"
            class="join-item border-y border-y-gray-200 card card-side"
            :href="trRoute('product', { product: trField(product, 'url') })"
            @click="hideSearch"
        >
          <div class="p-2 grow-0 shrink-0 basis-16">
            <ProductPicture
                :image="product.filename"
                :title="product.title"
                size="100"
            />
          </div>
          <div class="card-body p-2">
            <div class="card-title text-base lg:text-lg">{{ trField(product, "title") }}</div>
            <div class="flex flex-row gap-2 justify-between">
              <div class="w-1/2 lg:w-1/3 text-left text-xs">
                {{ product.code }}
              </div>
              <div class="w-1/4 lg:w-1/3 -mt-0.5">
                <ProductShipping
                    classes="text-sm"
                    :stocklevel="product.stocklevel"
                    :interim-stocklevel="product.interim_stocklevel"
                    :shipping-time="product.shipping_time"
                />
              </div>
              <div
                  class="w-1/4 lg:w-1/3 text-sm font-semibold text-right"
                  :class="{'text-secondary-500': product.good_price || hasDiscount(product.price_vat, product.price_default_vat, product.discount_start, product.discount_end)}">
                {{ price(product.price_vat) }}
              </div>
            </div>
          </div>
        </Link>
      </div>
      <Link v-if="data.rows > 0" :href="searchPage" class="join-item btn btn-ghost" @click="hideSearch">
        {{ __("Kõik tulemused") }} ({{ data.rows }})
        <ChevronRightIcon class="w-5 h-5"/>
      </Link>
    </div>
  </div>
</template>

<script setup>
import {ChevronRightIcon, MagnifyingGlassIcon, XMarkIcon} from "@heroicons/vue/24/solid";
import {__, hasDiscount, price, trField, trRoute} from "../utils";
import {onClickOutside, useDebounceFn} from "@vueuse/core";
import {computed, reactive, ref} from "vue";
import {Link, router} from "@inertiajs/vue3";
import request from "../request";
import ProductShipping from "../components/ProductShipping.vue";
import ProductPicture from "../components/ProductPicture.vue";

const searchbox = ref(null);
const props = defineProps({
  action: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: true
  },
  select: {
    type: Function,
    default: () => {
    }
  },
  noBtnAction: {
    type: Boolean,
    default: false
  }
});
const data = reactive({
  visible: false,
  rows: 0,
  searching: false,
  searchword: "",
  results: []
});
const isMiele = computed(() =>
    /(?:^|\s)(miele)(?=\s|$)/is.test(data.searchword.toLowerCase())
);
const searchPage = computed(() =>
    trRoute("search", {
      searchPhrase: data.searchword || "-",
      ...(isMiele.value ? {filters: "12_9134"} : {})
    })
);
const doSearch = (e) => {
  if (e.target.value.length >= 3) {
    if (e.keyCode === 13) {
      goToSearch();
    } else {
      debouncedSearch();
    }
  }
};
const goToSearch = () => {
  if (props.noBtnAction) {
    debouncedSearch();
  } else {
    router.visit(searchPage.value);
  }
};
const clearSearch = () => {
  hideSearch();
  data.results = [];
  data.searchword = "";
};
const hideSearch = () => {
  data.visible = false;
};
onClickOutside(searchbox, hideSearch);
const showResults = () => {
  if (data.results && data.results.length > 0 && !data.visible) {
    data.visible = true;
  }
};
const search = () => {
  data.searching = true;
  request
      .get(props.action, {params: {query: data.searchword || "-"}})
      .then((res) => {
        data.searching = false;
        data.results = res.data.results;
        data.rows = res.data.rows;
        data.visible = true;
      })
      .catch(() => {
      });
};
const debouncedSearch = useDebounceFn(search, 500);
</script>